import { useSelector } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'


const Card = ({data,trending,index,media_type }) => {
    const imageURL = useSelector(state => state.movieData.imageURL)

    const mediaType = data.media_type ?? media_type
    const altText = data.title || data.name || "Imagem do filme"

  return (
    <Link to={"/"+mediaType+"/"+data.id} className='w-full min-w-[230px] max-w-[230px] h-80 overflow-hidden block rounded relative hover:scale-105 transition-all'>
        
        {
            data?.poster_path ? (
                <img
                    src={imageURL+data?.poster_path}
                    alt={altText}
                />
            ) : (
                <div className='bg-neutral-800 h-full w-full flex justify-center items-center'>
                    Imagem não encontrada.
                </div>
            )

        }

        

        <div className='absolute top-4 '>
            {
                trending && (
                    <div className='py-1 px-4 backdrop-blur-3xl rounded-r-full bg-black/60 overflow-hidden'>
                        #{index} Em alta
                    </div>
                )
            }
        </div>

        <div className='absolute bottom-0 h-16 backdrop-blur-3xl w-full  bg-black/60 p-2'>
            <h2 className='text-ellipsis line-clamp-1 text-lg font-semibold'>{data?.title || data?.name}</h2>
            <div className='text-sm text-neutral-400 flex justify-between items-center'>
                <p>{ moment(data.release_date).format("DD/MM/YYYY") }</p>
                <p className='bg-black px-1 rounded-full text-xs text-white'>Avaliações :{Number(data.vote_average).toFixed(1)}</p>
            </div>
        </div>
    </Link>
  )
}

Card.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        media_type: PropTypes.string,
        poster_path: PropTypes.string,
        title: PropTypes.string,
        name: PropTypes.string,
        release_date: PropTypes.string,
        vote_average: PropTypes.number
    }).isRequired,
    trending: PropTypes.bool,
    index: PropTypes.number,
    media_type: PropTypes.string
}

export default Card